import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import Layout from "../containers/Home/Layout";
import "common/assets/css/main-page.css";
import ServicesWrapper from "../containers/Home/services.style";

import Seo from "components/seo";
import { Link } from "gatsby";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Fade from "react-reveal/Fade";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Services = () => {
  const seoData = {
    title: "Hair Wig & Extension Services | Radiance Hair Studio",
    description: "Explore Radiance Hair Studio’s premium services, including hair wigs, extensions, hair loss treatments, and more. Get a natural look today!",
    keywords: ["Our Services"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "image": "https://www.radiancehairstudio.com/home/home-image-18.webp",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "Our Services",
    "additionalType": "https://www.radiancehairstudio.com/services.php",
    "category": "https://www.radiancehairstudio.com/services.php",
    "description": "Explore Radiance Hair Studio’s premium services, including hair wigs, extensions, hair loss treatments, and more. Get a natural look today!"
  };    

  const womensHairServices = [
    {
      title: "Customized Wigs",
      image: "/home/customized-wigs-img.png",
      link: "/customized-wigs-for-women.php",
      desc: "Wigs can be used if you are losing an excessive amount of hair or anything related to that. Custom Made wigs are something which can be made according to your preference and most importantly which will suit you perfectly!."
    },
    {
      title: "Volumizer Hair Additions",
      image: "/home/women-volumizer-hair-additions-slider.png",
      link: "/volumizer-hair-additions-for-women.php",
      desc: "The hair additions are tied manually by our experts to the roots of existing mane, thus the additions manage to blend with your mane and add the extra volume boosting your confidence."
    },
    {
      title: "Hair Bonding for Womem",
      image: "/home/hair-bonding-for-womem.png",
      link: "/hair-bonding-for-women.php",
      desc: "The major transformation from BALD to BOLD is just the CAPITAL away from you. RADIANCE studio provides the best hair fixing in Delhi. "
    },
    {
      title: "Hair Clipping",
      image: "/home/tape-in-clipping-in-extension.png",
      link: "/tape-in-and-clip-in-hair-extension.php",
      desc: "Hair extensions are applied on your head usually in three styles – applying glues, using tape-ins and by using clip-ins."
    },
    {
      title: "Hair extensions",
      image: "/home/hair-extensions.png",
      link: "/hair-extention-for-womens.php",
      desc: "Watching our grandparents adoring their luscious mane has infused within us the DESIRE of owning long hair since our very CHILDHOOD. It seems that FASHION senses and LIFESTYLE had different plans to be implemented. But the DESIRE didn't take a LEAVE! To all our hair goals HAIR EXTENTION is the key."
    },
    {
      title: "Hair Weaving",
      image: "/home/hair-weaving.png",
      link: "/hair-bonding-for-women.php",
      desc: "Women are the future! Working hard and earning their dream job. Though the same has contributed to their improper lifestyles. "
    },
    {
      title: "non surgical Hair replacement",
      image: "/home/non-surgical-hair-replacement.png",
      link: "/non-surgical-hair-replacement.php",
      desc: "Hair fall has now become a regular problem for women living in the metros. Oral medicines are there in the market with their promises to grow your hair naturally."
    },
    {
      title: "Wigs for Cancer Patients",
      image: "/home/wigs-for-cancer-patients.png",
      link: "/womens-wigs-for-cancer-patients.php",
      desc: "Hair adds to the physical beauty of an individual, boosts our confidence and reflects your unique character. And no matter what comes in the way, CONFIDENCE should never be COMPROMISED upon! Wigs are thus the go-to solution while undergoing chemo."
    },
    {
      title: "wigs for women",
      image: "/home/wigs-for-women.png",
      link: "/wigs-for-womens.php",
      desc: "Women have always chosen to reflect their personality and vibes through what they wear? and how they wear! It’s simply like YOU ARE WHAT YOU WEAR! But the lifestyle we choose has different plans may be. Hair fall and thinning are the two most common Devils enough to ruin the MANE MOOD."
    },
    {
      title: "Hair Replacement For Women",
      image: "/home/hair-replacement-for-women.png",
      link: "/hair-replacement-for-women.php",
      desc: "Wigs can be used if you are losing an excessive amount of hair or anything related to that. Custom Made wigs are something which can be made according to your preference and most importantly which will suit you perfectly!."
    },
  ];

  const mensHairServices = [
    {
      title: "Men’s Hair Replacement System",
      image: "/home/mens-hair-replacement-img.png",
      link: "/hair-replacement-for-men.php",
      desc: "The best way to deal with your baldness, semi-permanent hair loss and even accept a frequent change of hairstyles is to go for hair wigs in Delhi. You will have a number of choices at your hand in the case. Over the above, you will not experience any sort of side-effects."
    },
    {
      title: "Wigs for Men",
      image: "/home/wigs-for-men-img.png",
      link: "/wigs-for-men.php",
      desc: "Style reflects the CHARACTER of an individual. When it comes to MEN, their personality should hold a charge. The hair is something that can change an entire look."
    },
    {
      title: "Hair Patch for Men",
      image: "/home/hair-patch-for-men-slider.png",
      link: "/hair-patch-mens.php",
      desc: "Every day all of us loses hair, it may be even up to 100 strands a day. But in general hair which falls out, then grow back in, and the cycle starts over again. But eventually, many of us will start to notice that we are losing more hair than before -- and it’s not growing back."
    },
    {
      title: "Non-Surgical Hair Replacement",
      image: "/home/non-surgical-hair-replacement-slider.png",
      link: "/non-surgical-hair-replacement-for-men.php",
      desc: "Fashion is the underlined statement in the city life today. To be synchronized with it is the call of the hour for you now. "
    },
    {
      title: "Human Hair Extension",
      image: "/home/human-hair-extension-slider.png",
      link: "/hair-extensions-for-men.php",
      desc: "Watching our grandparents adoring their luscious mane has infused within us the DESIRE of owning long hair since our very CHILDHOOD. It seems that FASHION senses and LIFESTYLE had different plans to be implemented. But the DESIRE didn't take a LEAVE! To all our hair goals HAIR EXTENTION is the key."
    },
    {
      title: "Customized Wigs",
      image: "/home/customized-wigs-slider.png",
      link: "/customized-wigs-for-men.php",
      desc: "At Radiance, we provide all males a perfectly knitted wig with awesome measurement and quality. We provide you with the perfect material design only – human hair and freestyle."
    },
    {
      title: "Hair Bonding",
      image: "/home/hair-bonding-slider.png",
      link: "/hair-bonding-for-men.php",
      desc: "Radiance Hair Salon is ready to give you a wide range of style in this aspect. Try out a new looking every alternate day and start stunning your colleagues and friends. Hair bonding is going to open up a new horizon to your stylish life."
    },
    {
      title: "Hair Weaving",
      image: "/home/hair-weaving-slider.png",
      link: "/hair-weaving-for-mens.php",
      desc: "Radiance Hair Salon will be making a structure on your head using your natural hair only and then will be stitching the natural hair elongations with the structure itself. The process is a long drawn one, but the change you will find is perfectly natural."
    },
    {
      title: "Tape In & Clip In on Hair Extensions",
      image: "/home/tape-in-clip-in-on-hair-extensions.png",
      link: "/tape-in-and-clip-in-hair-extension.php",
      desc: "Hair extensions are applied on your head usually in three styles – applying glues, using tape-ins and by using clip-ins. Although the glues applied are medicated, still, some feel a panic to apply the chemicals. In such cases, tape-ins and clip-ins are the best options. In the case of tape-ins, both side tapes are applied o your scalp and extensions are attached to it."
    },
    {
      title: "Wigs for Cancer Patients",
      image: "/home/wigs-for-cancer-patients-banner.png",
      link: "/wigs-for-men-cancer-patients.php",
      desc: "Cancer patients are treated with Chemotherapies. Most of the time Chemo is provided as rays, but at times oral drugs are also used rigorously. In both cases, hair loss is quite obvious. To hide your baldness, resulting out of it, apply hair wigs in Delhi."
    },
    {
      title: "Hair Fixing",
      image: "/home/hair-fixing-slider.png",
      link: "/hair-fixing.php",
      desc: "Baldness, extreme hair fall and hair fall at some parts of your scalp – these three are the major reasons to undergo hair loss treatment. You mostly concentrate here on the types of hair treatment, but methods applied for hair fixing in Delhi is another major area, that you must look about."
    },
  ];

  const Card = ({ title, image, link, desc }) => {
    return (
      <div className="serviceCard col-md-4">
        <Link to={link}>
          <img src={image} alt={title} />
          <h2>{title}</h2>
        </Link>
      </div>
    );
  };

  const CardNew = ({ title, image, link, desc }) => {
    return (
      <div className="serviceCardNew">
        <Link to={link}>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-6">
              <h2>{title}</h2>
              <p>{desc.length > 90 ? `${desc.slice(0, 90)}...` : desc}</p>
              <a href={link}>Get Now</a>
            </div>
            <div className="col-md-6">
              <img src={image} alt={title} />
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const CardNewBg = ({ title, image, link, desc }) => {
    return (
      <div className="serviceCardNew2 cardbg" style={{background: `url(${image})`}}>
        <Link to={link}>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-12">
              <h2>{title}</h2>
              
              <a className="btncard" href={link}>Get Now</a>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const swiperBreakpoints = {
    // When viewport width is less than or equal to 767px (typical mobile size)
    767: {
      slidesPerView: 2, // Display 2 slides on mobile
      spaceBetween: 20, // Adjust as needed
    },
    // When viewport width is greater than 767px (larger screens)
    768: {
      slidesPerView: 2, // Display 1 slide on larger screens
      spaceBetween: 20, // Adjust as needed
    },
  };

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <ServicesWrapper>
            <div className="services-body">
              <main className="services-page">
                <section className="title-bar">
                  <div className="title-bar-overlay"></div>
                  <div className="section-container">
                    <h1 className="title-bar-heading">Services</h1>
                    <h2 className="title-bar-breadcrumb">
                      <a href="/">Home</a> / <a href="#">Services</a>
                    </h2>
                  </div>
                </section>

                <section className="title-bar-separator">
                  <img
                    className="separator-image"
                    alt=""
                    src="/images/separator.png"
                  />
                </section>

                <section className="services-section-one">
                  <div className="myContainer">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="headB">
                          <Fade left>
                            <p>Why Choose Us</p>
                            <h3 className="title">Radiance Hair Studio?</h3>
                          </Fade>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="firstpara">
                          <Fade right>
                            <p className="who-we-are-sec pt-20">
                              At <a href="/" style={{ color: "#373737"}}> Radiance Hair Studio,</a> our goal is to make every
                              client look at their very best. Our clients
                              receive high-quality, one-on-one attention. Each
                              client receives a complete consultation before any
                              procedure so he or she can decide the best
                              personal course of action.
                            </p>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="services-section-two">
                  <div className="section-container">
                    <div className="content-area-center">
                      <h2 className="title">Hair Services for Men</h2>

                      <Swiper
                        spaceBetween={0} // Adjust as needed
                        slidesPerView={1} // Display 4 slides per view
                        loop={true}
                        navigation
                        autoplay={{ delay: 3000 }}
                        pagination={{ clickable: true }}
                        breakpoints={swiperBreakpoints}
                        // pagination={{ clickable: true }}
                      >
                        {mensHairServices.map((service, index) => (
                          <SwiperSlide>
                            <CardNewBg key={index} {...service} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </section>

                <section
                  className="services-section-two"
                  style={{ background: "#F4F3F3" }}
                >
                  <div className="section-container">
                    <div className="content-area-center">
                      <h2 className="title">Hair Services for Women</h2>

                      <Swiper
                        spaceBetween={0} // Adjust as needed
                        slidesPerView={1} // Display 4 slides per view
                        loop={true}
                        navigation
                        autoplay={{ delay: 3000 }}
                        pagination={{ clickable: true }}
                        breakpoints={swiperBreakpoints}
                        // pagination={{ clickable: true }}
                      >
                        {womensHairServices.map((service, index) => (
                          <SwiperSlide>
                            <CardNewBg key={index} {...service} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </section>

                <section className="services-section-four">
                  <div className="section-container">
                    <div
                      className="offer-container"
                      style={{
                        backgroundImage: 'url("/images/discount-banner.jpg")',
                      }}
                    >
                      <div className="offer-container-content">
                        <h2 className="title-1">THIS WEEK ONLY</h2>
                        <h3 className="title-2">
                          Get <span className="big-title">30% OFF</span>
                        </h3>
                        <h4 className="title-3">
                          Feel free to express yourself through your looks!
                        </h4>
                        <a className="border-button" href="#">
                          BOOK AN APPOINTMENT
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </ServicesWrapper>

          <div
            className="container servicesCardrow"
            style={{ display: "none" }}
          >
            <h3 style={{ textAlign: "center", marginBottom: "15px" }}>
              Hair Service for Men
            </h3>
            <div className="row">
              {mensHairServices.map((service, index) => (
                <Card key={index} {...service} />
              ))}
            </div>
          </div>
          <div
            className="container servicesCardrow"
            style={{ display: "none" }}
          >
            <h3 style={{ textAlign: "center", marginBottom: "15px" }}>
              Hair Service for Women
            </h3>
            <div className="row">
              {womensHairServices.map((service, index) => (
                <Card key={index} {...service} />
              ))}
            </div>
          </div>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Services;
